import styled, { createGlobalStyle } from "styled-components";
import ErrorBoundary from "./ErrorBoundary";
import AppStateProvider from "./providers/AppStateProvider";
import CopyProvider from "./providers/CopyProvider/CopyProvider";
import UserProvider from "./providers/UserProvider";
import ItemProvider from "./providers/ItemProvider";
import AttributeProvider from "./providers/AttributeProvider";
import { v4 as uuidv4 } from "uuid";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.light};
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }
`;

const AppContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.default};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const App = ({ children }) => {
  return (
    <AppContainer>
      <CopyProvider lang={navigator.language}>
        <ErrorBoundary>
          <GlobalStyle />
          <AttributeProvider>{children}</AttributeProvider>
        </ErrorBoundary>
      </CopyProvider>
    </AppContainer>
  );
};

const Authenticated = ({ children }) => {
  return (
    <UserProvider>
      <ItemProvider>
        <AppStateProvider>{children}</AppStateProvider>
      </ItemProvider>
    </UserProvider>
  );
};

export default App;

export { Authenticated };
export const appInstanceId = uuidv4();
