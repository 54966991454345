import { useState, useContext } from "react";
import styled from "styled-components";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Link } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import { validateEmail } from "@sussex/react-kit/utils";
import { UserContext } from "../../providers/UserProvider";
import Confirm from "../Confirm";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import useCopy from "../../hooks/useCopy";
import {
  PrimaryHeading,
  Header,
  Form,
  Body,
  Heading,
  TextField,
  Submit,
  ErrorMessage,
  Footer,
} from "./Elements";

import therapistImage from "../../assets/therapist.png";

const Image = styled.img`
  margin: 25px;
`;

const LinkText = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.normal};
  margin-bottom: 24px;
`;

const SignIn = ({ forgotPasswordAction }) => {
  const [
    titleText,
    emailText,
    passwordText,
    genericErrorText,
    signInText,
    forgotText,
    invalidEmailText,
    invalidPasswordText,
    credentialsErrorText,
  ] = useCopy([
    "client.signIn.title",
    "client.signIn.email",
    "client.signIn.password",
    "client.signIn.error",
    "client.signIn.signIn",
    "client.signIn.forgotPassword",
    "client.signIn.emailError",
    "client.signIn.passwordError",
    "client.signIn.incorrectError",
  ]);
  const { signInUser } = useContext(UserContext);
  const [confirmUser, setConfirmUser] = useState(false);
  const [tempUser, setTempUser] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailValid = validateEmail(email);
  const passwordValid = password.length >= 8;

  const signIn = e => {
    e.preventDefault();
    setLoading(true);
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
      Storage: MemoryStorage,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        cognitoUser.getUserAttributes((err, attrResult) => {
          setLoading(false);

          if (err) {
            console.error("Error getting user attributes", err);
            return;
          }

          let uuid = "",
            name = "",
            phoneNumber = "",
            locale = navigator.language;

          attrResult.forEach(a => {
            if (a.Name === "sub") {
              uuid = a.Value;
            }
            if (a.Name === "name") {
              name = a.Value;
            }
            if (a.Name === "phone_number") {
              phoneNumber = a.Value;
            }
            if (a.Name === "locale") {
              locale = a.Value;
            }
          });
          const idToken = result.idToken.jwtToken;

          signInUser({
            username: email,
            uuid,
            name,
            email,
            phoneNumber,
            locale,
            idToken,
          });
        });
      },
      onFailure: err => {
        setLoading(false);
        if (err.message === "User is not confirmed.") {
          setTempUser(cognitoUser);
          setConfirmUser(true);
          return;
        }
        if (err.message === "Incorrect username or password.") {
          setLoginError(credentialsErrorText);
          return;
        }
        setLoginError(genericErrorText);
        console.error(err.message || JSON.stringify(err));
      },
    });
  };

  const checkEmail = () => {
    setEmailError(emailValid ? false : invalidEmailText);
  };

  const checkPassword = () => {
    setPasswordError(passwordValid ? false : invalidPasswordText);
  };

  const handleEmailChange = ({ target }) => {
    setEmailError(false);
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPasswordError(false);
    setPassword(target.value);
  };

  if (confirmUser) {
    const authDetails = { email, password };
    return <Confirm authDetails={authDetails} user={tempUser} />;
  }

  return (
    <Form onSubmit={signIn}>
      <Header>
        <Image src={therapistImage} alt={titleText} />
        <PrimaryHeading>{titleText}</PrimaryHeading>
      </Header>
      <Body>
        <Heading>{signInText}</Heading>
        {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
        {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
        <TextField
          label={emailText}
          value={email}
          type="email"
          onChange={handleEmailChange}
          onBlur={checkEmail}
        />
        <TextField
          label={passwordText}
          value={password}
          type="password"
          onChange={handlePasswordChange}
          onBlur={checkPassword}
        />
        <LinkText onClick={forgotPasswordAction}>{forgotText}</LinkText>
      </Body>
      <Footer>
        <Submit
          type="submit"
          disabled={!(emailValid && passwordValid)}
          onClick={signIn}
        >
          {loading ? <Loading /> : signInText}
        </Submit>
      </Footer>
    </Form>
  );
};

export default SignIn;
