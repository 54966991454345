import { useState } from "react";
import styled from "styled-components";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Link } from "@sussex/react-kit/elements";
import { validateEmail } from "@sussex/react-kit/utils";
import { Loading } from "@sussex/react-kit/assets";
import useCopy from "../../hooks/useCopy";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import {
  Form,
  Heading,
  Body,
  Footer,
  TextField,
  ErrorMessage,
  Submit,
} from "./Elements";
import Confirm from "../Confirm";
import { getUserStatus } from "../../httpapi";

const LinkText = styled(Link)`
  margin-top: 24px;
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const Instructions = styled.div`
  margin: 25px 0;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const ForgotPassword = ({ onBack, onSuccess }) => {
  const [
    titleText,
    instructions,
    backText,
    errorText,
    identityVerificationTitle,
    identityVerificationMessage,
    emailText,
    submitText,
    invalidEmailText,
  ] = useCopy([
    "client.forgotPassword.title",
    "client.forgotPassword.instructions",
    "client.resetPassword.backAction",
    "client.forgotPassword.sendCodeError",
    "client.forgotPassword.identityVerificationTitle",
    "client.forgotPassword.identityVerificationMessage",
    "client.signIn.email",
    "client.signIn.next",
    "client.signIn.emailError",
  ]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [confirmationNeeded, setConfirmationNeeded] = useState(false);
  const emailValid = validateEmail(email);

  const userData = {
    Username: email,
    Pool: userPool,
    Storage: MemoryStorage,
  };

  const cognitoUser = new CognitoUser(userData);

  const sendConfirmationCode = () => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error(err);
        setError(errorText);
        setLoading(false);
        return;
      }
      setConfirmationNeeded(true);
    });
  };

  const sendForgotPasswordCode = () => {
    cognitoUser.forgotPassword({
      onSuccess: () => {
        setLoading(false);
        onSuccess(email);
      },
      onFailure: err => {
        setLoading(false);
        if (err) {
          console.error(err);
          setError(errorText);
        }
      },
    });
  };

  const onConfirmationSuccess = () => {
    setConfirmationNeeded(false);
    setTimeout(() => {
      sendForgotPasswordCode();
    }, 1000);
  };

  const checkEmail = () => {
    setEmailError(emailValid ? false : invalidEmailText);
  };

  const handleEmailChange = ({ target }) => {
    setEmailError(false);
    setEmail(target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    const status = await getUserStatus(email);

    if (status === "UNCONFIRMED") {
      sendConfirmationCode();
    } else {
      sendForgotPasswordCode();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {confirmationNeeded ? (
        <>
          <Heading>{identityVerificationTitle}</Heading>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Instructions>{identityVerificationMessage}</Instructions>
          <Confirm
            authDetails={{ email }}
            user={cognitoUser}
            next={onConfirmationSuccess}
            skipLogin={true}
          />
        </>
      ) : (
        <>
          <Body>
            <Heading>{titleText}</Heading>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
            <Instructions>{instructions}</Instructions>
            <TextField
              label={emailText}
              value={email}
              type="email"
              onChange={handleEmailChange}
              onBlur={checkEmail}
            />
            <LinkText onClick={onBack}>{backText}</LinkText>
          </Body>
          <Footer>
            <Submit type="submit" disabled={email === "" || emailError}>
              {loading ? <Loading /> : `${submitText}`}
            </Submit>
          </Footer>
        </>
      )}
    </Form>
  );
};

export default ForgotPassword;
