import { useEffect, createContext, useState } from "react";
import enGB from "../../locale/en-GB.json";
import enUS from "../../locale/en-US.json";
import esES from "../../locale/es-ES.json";
import frFR from "../../locale/fr-FR.json";

export const CopyContext = createContext(null);

const localeLanguages = {
  "en-GB": enGB,
  "en-US": enUS,
  "es-ES": esES,
  "fr-FR": frFR,
};
const defaultLanguage = enGB;
const defaultLanguages = {
  en: enGB,
  es: esES,
  fr: frFR,
};

export const getFallbackLanguage = lang => {
  const prefix = lang.split("-")[0];
  return defaultLanguages[prefix] || defaultLanguage;
};

const CopyProvider = ({ lang, children }) => {
  const [copy, setCopy] = useState({});

  useEffect(() => {
    const translations = localeLanguages[lang] || getFallbackLanguage(lang);
    setCopy(translations);
  }, [lang]);

  return <CopyContext.Provider value={copy}>{children}</CopyContext.Provider>;
};

export default CopyProvider;
