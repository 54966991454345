import { useState, useEffect } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { ErrorBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import useCopy from "../hooks/useCopy";
import { tokenRefuseMatch } from "../httpapi";
import PanelHeader from "../components/PanelHeader";
import { useNavigate } from "react-router-dom";
import PageWrapper from "./shared/PageWrapper";
import PanelWrapper from "./shared/PanelWrapper";
import LoginLink from "./shared/LoginLink";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const Inner = styled.div`
  max-width: 525px;
  margin: 10px auto 0;
  padding: 16px;
`;

const LoginWrapper = styled.div`
  margin: 30px 0;
`;

const Panel = styled.div`
  background: #fff;
  flex: 1;
`;

export default function DeclineAppointmentView() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [loginErrorText, errorTitle, duplicateText, genericErrorText] = useCopy(
    [
      "client.confirmLanding.loginText.error",
      "client.confirmLanding.errorTitle",
      "client.confirmLanding.duplicateConfirmation",
      "client.confirmLanding.genericError",
    ],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!token) {
      setError("generic");
      return;
    }

    const load = async () => {
      const res = await tokenRefuseMatch({ token });
      if (res.success) {
        navigate("/feedback/decline-match", { state: { token } });
        return;
      }
      setError(res.conflict ? "conflict" : "generic");
    };

    load();
  }, [navigate]);

  return (
    <PageWrapper>
      <PanelWrapper>
        {error ? (
          <Panel>
            <PanelHeader title={errorTitle} back={null} close={false} />
            <Inner>
              <ErrorBox>
                {error === "conflict" ? duplicateText : genericErrorText}
              </ErrorBox>
              <LoginWrapper>
                {swapCopyVariables(loginErrorText, { LINK: <LoginLink /> })}
              </LoginWrapper>
            </Inner>
          </Panel>
        ) : (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
      </PanelWrapper>
    </PageWrapper>
  );
}
