import styled from "styled-components";
import { Logo } from "@sussex/react-kit/assets";

const Wrapper = styled.div`
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.primaryBlue};
`;

const LogoWrapper = styled.div`
  svg {
    width: 135px;
    height: 32px;
  }
`;

const Header = ({ right }) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {right && right()}
    </Wrapper>
  );
};

export default Header;
