import styled from "styled-components";
import Photo from "../Photo";
import { Button } from "@sussex/react-kit/elements";

const Wrapper = styled.div`
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.light};
  max-width: 500px;
  padding: 20px 15px;
  margin: 0 auto;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Information = styled.div``;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Title = styled.div``;

const AppointmentDetails = styled.div`
  margin-bottom: 20px;
  word-break: break-word;
`;

const CalendarLinks = styled.div`
  display: grid;
  gap: 10px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CalendarButton = styled(Button)`
  min-width: auto;
`;

const AppointmentCard = ({
  photoItem,
  name,
  title,
  appointmentDetails,
  calendarLinks,
}) => {
  return (
    <Wrapper>
      {photoItem && name && (
        <Details>
          <Photo item={photoItem} />
          <Information>
            <Name>{name}</Name>
            {title && <Title>{title}</Title>}
          </Information>
        </Details>
      )}
      <AppointmentDetails>{appointmentDetails}</AppointmentDetails>
      <CalendarLinks>
        {Object.keys(calendarLinks).map(name => (
          <CalendarButton
            key={name}
            onClick={() => window.open(calendarLinks[name], "_blank")}
          >
            {name}
          </CalendarButton>
        ))}
      </CalendarLinks>
    </Wrapper>
  );
};

export default AppointmentCard;
