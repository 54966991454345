import { useState, useEffect, useRef, useCallback } from "react";

const inactivityThreshold = 15 * 1000; // 15 secs

const events = ["mousemove", "mousedown", "touchstart", "keydown"];

export default function useIsActive() {
  const activeTimer = useRef(null);
  const [active, setActive] = useState(true);
  const [lastActivityDate, setLastActivityDate] = useState(null);

  const deleteTimer = useCallback(() => {
    if (activeTimer.current) {
      clearTimeout(activeTimer.current);
      activeTimer.current = null;
    }
  }, []);

  const createTimer = useCallback(() => {
    deleteTimer();
    setActive(true);

    const now = Math.floor(new Date().getTime() / 1000);
    if (now > lastActivityDate) {
      setLastActivityDate(now);
    }

    activeTimer.current = setTimeout(() => {
      setActive(false);
    }, inactivityThreshold);
  }, [deleteTimer, lastActivityDate]);

  useEffect(() => {
    createTimer();

    events.forEach(evt => {
      document.addEventListener(evt, createTimer);
    });

    return () => {
      deleteTimer();

      events.forEach(evt => {
        document.removeEventListener(evt, createTimer);
      });
    };
  }, [createTimer, deleteTimer]);

  return {
    active,
    lastActivityDate,
  };
}
