import styled, { useTheme } from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Statuses } from "@sussex/match-utils";
import useCopy from "../../hooks/useCopy";
import Success from "../../assets/Success";
import { AppointmentCard, AppointmentDetails } from "../AppointmentCard";
import calendarLinks from "../AppointmentCard/calendarlinks";

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Intro = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
`;

const Section = styled.div`
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 15px;
  margin: 15px;
`;

const Wrapper = styled.div`
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.light};
  padding: 20px 15px;
  margin-bottom: 15px;
`;

const DetailText = styled.div`
  margin-top: 15px;
`;

const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const StatusMessage = ({ item, children }) => {
  const [
    successText,
    successIntro,
    confirmedText,
    confirmedIntro,
    confirmedMessage,
    scheduledText,
    scheduledIntro,
    refusedText,
    refusedIntro,
    calendarSummary,
    calendarDetails,
  ] = useCopy([
    "client.matchSuccess",
    "client.matchSuccess.intro",
    "client.matchConfirmed",
    "client.matchConfirmed.intro",
    "client.matchConfirmed.message",
    "client.matchScheduled",
    "client.matchScheduled.intro",
    "client.matchRefused",
    "client.matchRefused.intro",
    "client.confirmLanding.appointmentDetails.summary",
    "client.confirmLanding.appointmentDetails.details",
  ]);

  const theme = useTheme();
  const {
    colors: { default: defaultColor },
  } = theme;

  const timeStr = item.proposedDatetime
    ? new Date(item.proposedDatetime * 1000).toLocaleDateString(
        navigator.language,
        {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
      )
    : "";

  const getContent = () => {
    switch (item.status) {
      case Statuses.accepted:
        return (
          <Section>
            <Title>
              <Success fill="white" stroke={defaultColor} />
              {successText}
            </Title>
            <Intro>
              {swapCopyVariables(successIntro, {
                VALUE: timeStr,
              })}
            </Intro>
            {children}
          </Section>
        );

      case Statuses.confirmed:
        return (
          <Section>
            <Wrapper>
              {swapCopyVariables(confirmedMessage, {
                NAME: item.therapist.fullName,
                DATE: <Bold>{timeStr}</Bold>,
              })}
              {item.otherVideoDetail && (
                <DetailText>"{item.otherVideoDetail}"</DetailText>
              )}
              {item.appointmentDetail && (
                <DetailText>"{item.appointmentDetail}"</DetailText>
              )}
            </Wrapper>
            <Title>
              <Success fill="white" stroke={defaultColor} />
              {confirmedText}
            </Title>
            <Intro>
              {swapCopyVariables(confirmedIntro, {
                NAME: item.therapist.fullName,
              })}
            </Intro>
            {children}
          </Section>
        );

      case Statuses.scheduled:
        return (
          <Section>
            <Title>
              <Success fill="white" stroke={defaultColor} />
              {scheduledText}
            </Title>
            <Intro>{scheduledIntro}</Intro>
            <AppointmentCard
              appointmentDetails={
                <AppointmentDetails match={item} therapist={item.therapist} />
              }
              calendarLinks={calendarLinks({
                startTime: item.proposedDatetime,
                endTime: item.proposedEndDatetime,
                method: item.appointmentMethod,
                locationUrl:
                  item.appointmentMethod === "sessions" ? item.sessionsUrl : "",
                summary: encodeURIComponent(calendarSummary),
                details: encodeURIComponent(
                  swapCopyVariables(calendarDetails, {
                    NAME: item.therapist.fullName,
                  }).join(""),
                ),
              })}
            />
            {children}
          </Section>
        );

      case Statuses.refused:
        return (
          <Section>
            <Title>
              <Success fill="white" stroke={defaultColor} />
              {refusedText}
            </Title>
            <Intro>{refusedIntro}</Intro>
            {children}
          </Section>
        );

      default:
        return children;
    }
  };

  return getContent();
};

export default StatusMessage;
