import { useState, useEffect } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { ErrorBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import useCopy from "../hooks/useCopy";
import { tokenConfirmMatch, tokenGetMatch, getTherapist } from "../httpapi";
import PanelHeader from "../components/PanelHeader";
import MatchAppointmentCard from "../components/AppointmentCard";
import PageWrapper from "./shared/PageWrapper";
import PanelWrapper from "./shared/PanelWrapper";
import LoginLink from "./shared/LoginLink";
import { useNavigate } from "react-router-dom";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const Subtitle = styled.div`
  margin: 30px 0;
  text-align: center;
`;

const Inner = styled.div`
  max-width: 525px;
  margin: 10px auto 0;
  padding: 16px;
`;

const LoginWrapper = styled.div`
  margin: 30px 0;
`;

const AppointmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Panel = styled.div`
  background: #fff;
  flex: 1;
`;

export default function ConfirmAppointmentView() {
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState(null);
  const [therapist, setTherapist] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [
    title,
    loginText,
    loginErrorText,
    emailText,
    errorTitle,
    duplicateText,
    genericError,
  ] = useCopy([
    "client.confirmLanding.title",
    "client.confirmLanding.loginText",
    "client.confirmLanding.loginText.error",
    "client.confirmLanding.emailText",
    "client.confirmLanding.errorTitle",
    "client.confirmLanding.duplicateConfirmation",
    "client.confirmLanding.genericError",
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const schedule = params.get("schedule");
    const acceptDatetime = schedule === "true";

    if (!token) {
      setError("generic");
      setLoading(false);
      return;
    }

    const load = async () => {
      let matchRes = null;
      let therapistRes = null;

      if (acceptDatetime) {
        matchRes = await tokenGetMatch({ token });
        if (!matchRes.success) {
          setError("generic");
          setLoading(false);
          return;
        }

        therapistRes = await getTherapist(matchRes.profileId);
        if (!therapistRes.success) {
          setError("generic");
          setLoading(false);
          return;
        }
      }

      const res = await tokenConfirmMatch({ token, acceptDatetime });
      if (!res.success) {
        setError(res.conflict ? "conflict" : "generic");
        setLoading(false);
        return;
      }

      if (!acceptDatetime) {
        navigate("/");
        return;
      }

      setMatch(matchRes);
      setTherapist(therapistRes);
      setLoading(false);
    };

    load();
  }, [navigate]);

  if (loading) {
    return (
      <PageWrapper>
        <PanelWrapper>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  if (error) {
    const errorText = {
      generic: genericError,
      conflict: duplicateText,
    };

    return (
      <PageWrapper>
        <PanelWrapper>
          <Panel>
            <PanelHeader title={errorTitle} back={null} close={false} />
            <Inner>
              <ErrorBox>{errorText[error]}</ErrorBox>
              <LoginWrapper>
                {swapCopyVariables(loginErrorText, { LINK: <LoginLink /> })}
              </LoginWrapper>
            </Inner>
          </Panel>
        </PanelWrapper>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PanelWrapper>
        <Panel>
          <PanelHeader title={title} back={null} close={false} />
          <Subtitle>{emailText}</Subtitle>
          <AppointmentWrapper>
            <MatchAppointmentCard match={match} therapist={therapist} />
          </AppointmentWrapper>
          <Inner>
            <LoginWrapper>
              {swapCopyVariables(loginText, { LINK: <LoginLink /> })}
            </LoginWrapper>
          </Inner>
        </Panel>
      </PanelWrapper>
    </PageWrapper>
  );
}
