import { useState } from "react";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const SignInFlow = ({ page, onChange }) => {
  const [email, setEmail] = useState("");

  switch (page) {
    case "sign-in":
      return (
        <SignIn forgotPasswordAction={() => onChange("forgot-password")} />
      );
    case "forgot-password":
      return (
        <ForgotPassword
          onSuccess={clientEmail => {
            setEmail(clientEmail);
            onChange("otp");
          }}
          onBack={() => onChange("sign-in")}
        />
      );
    case "otp":
      return (
        <ResetPassword
          email={email}
          onBack={() => onChange("sign-in")}
        />
      );
    default:
      return null;
  }
};

export default SignInFlow;
