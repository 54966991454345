import { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import { ChatFooter as Input } from "@sussex/react-kit/message-center";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Statuses } from "@sussex/match-utils";

import { sendMessage } from "../../httpapi";
import { UserContext } from "../../providers/UserProvider";
import { ItemContext } from "../../providers/ItemProvider";
import useCopy from "../../hooks/useCopy";
import { encryptText } from "../../idp";

const ChatInput = ({ item }) => {
  const { client } = useContext(UserContext);
  const { addPendingMessage, confirmPendingMessage, refusePendingMessage } =
    useContext(ItemContext);
  const [isSending, setIsSending] = useState(false);
  const [inputPlaceholder, inputPlaceholderNoName, tooltip] = useCopy([
    "messageCenter.matchChat.inputPlaceholder",
    "messageCenter.matchChat.inputPlaceholderNoName",
    "messageCenter.matchChat.clientDisabledTooltip",
  ]);

  const chatLocked =
    !item ||
    item?.type === "request" ||
    (item?.status !== Statuses.confirmed &&
      item?.status !== Statuses.scheduled);

  const handleSend = async message => {
    setIsSending(true);
    const tmpId = "tmp-" + uuidv4();
    addPendingMessage(item, tmpId, message);
    const encrypted = await encryptText(client, message);
    if (encrypted) {
      const res = await sendMessage({ itemId: item.id, message: encrypted });
      if (res.success) {
        confirmPendingMessage(item, tmpId, res.id, encrypted);
      } else {
        console.error(res.error);
        refusePendingMessage(item, tmpId);
      }
    } else {
      console.error("could not encrypt message");
      refusePendingMessage(item, tmpId);
    }
    setIsSending(false);
  };

  const getPlaceholder = () => {
    if (!item?.name || item.type === "request") {
      return inputPlaceholderNoName;
    }
    return swapCopyVariables(inputPlaceholder, {
      NAME: item?.title,
    }).join("");
  };

  return (
    <Input
      placeholder={getPlaceholder()}
      locked={chatLocked}
      disabled={isSending}
      tooltip={chatLocked ? tooltip : undefined}
      onSend={handleSend}
    />
  );
};

export default ChatInput;
