import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Anchor } from "@sussex/react-kit/elements";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

const DetailText = styled.div`
  margin-top: 15px;
`;

const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const AppointmentDetails = ({ match, therapist }) => {
  const [methodOther, methodPhone, methodSessions] = useCopy([
    "client.confirmLanding.appointmentDetails.other",
    "client.confirmLanding.appointmentDetails.phone",
    "client.confirmLanding.appointmentDetails.sessions",
  ]);
  const appointmentDate = new Date(
    match.proposedDatetime * 1000,
  ).toLocaleDateString(navigator.language, {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const methodText = () => {
    switch (match.appointmentMethod) {
      case "sessions":
        return swapCopyVariables(methodSessions, {
          NAME: therapist.fullName,
          DATE: <Bold>{appointmentDate}</Bold>,
          SESSIONS_URL: (
            <Anchor href={match.sessionsUrl}>{match.sessionsUrl}</Anchor>
          ),
        });
      case "other":
        return swapCopyVariables(methodOther, {
          NAME: therapist.fullName,
          DATE: <Bold>{appointmentDate}</Bold>,
        });
      case "phone":
        return swapCopyVariables(methodPhone, {
          NAME: therapist.fullName,
          DATE: <Bold>{appointmentDate}</Bold>,
          LAST_FOUR: <Bold>{match.phoneNumber.slice(-4)}</Bold>,
        });
      default:
        return "";
    }
  };
  return (
    <div>
      {methodText()}
      {match.otherVideoDetail && (
        <DetailText>"{match.otherVideoDetail}"</DetailText>
      )}
      {match.appointmentDetail && (
        <DetailText>"{match.appointmentDetail}"</DetailText>
      )}
    </div>
  );
};

export default AppointmentDetails;
