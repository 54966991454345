import { useState, useRef, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "@sussex/react-kit/hooks";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { ErrorExclamation } from "@sussex/react-kit/assets";
import dropdownArrow from "../../assets/dropdown-arrow.svg";
import backArrow from "../../assets/back-arrow.svg";
import Photo from "../Photo";
import Slideout from "../Slideout";
import { ItemContext } from "../../providers/ItemProvider";
import useCopy from "../../hooks/useCopy";
import ItemPanel from "./ItemPanel";
import Status from "../ItemStatus";

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  width: 9px;
  height: 15px;
  margin-right: 18px;

  &:after {
    content: "";
    background-image: url("${backArrow}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: none;
  }
`;
BackButton.defaultProps = {
  type: "button",
};

const PhotoWrapper = styled.div`
  flex: 0 0 auto;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
`;

const Details = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
`;

const DetailsLink = styled.div`
  cursor: pointer;
  display: inline;
  margin-left: 5px;
  text-decoration: underline;
`;

const NameWrapper = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  margin-right: auto;
`;

const Name = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallXl};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
`;

const Dropdown = styled.div`
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  width: 300px;
  left: 0;
  right: 0;
  top: 50px;
  margin: 0 auto;
  width: min(calc(100% - 60px), 300px);
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  z-index: 5;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    left: 90px;
    right: auto;
    margin: 0;
    top: 50px;
    width: 300px;
  }
`;

const DropdownItem = styled.div`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
`;

const ConfirmWrapper = styled.div``;

const ConfirmOverlay = styled.div`
  position: absolute;
  background: white;
  z-index: 10;
  opacity: 0.75;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

const ConfirmBox = styled.div`
  position: absolute;
  z-index: 11;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: min(calc(100% - 60px), 300px);
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 16px;
  background: white;
  z-index: 5;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
`;

const ConfirmTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallXl};
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const ConfirmPrompt = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin-bottom: 16px;
`;

const ConfirmIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.lightRed};
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ConfirmButton = styled.button`
  appearance: none;
  background: white;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1;
  padding: 20px 0;
  flex: 1;

  &:hover {
    background: ${({ theme }) => theme.colors.light};
  }
`;
ConfirmButton.defaultProps = {
  type: "button",
};

const ItemHeader = ({ item, showCancelConfirm, setShowCancelConfirm }) => {
  const navigate = useNavigate();
  const { getRelationship, cancelItem } = useContext(ItemContext);
  const dropdownRef = useRef(null);
  const onBack = () => navigate("/requests");
  const [dropdownActive, setDropdwonActive] = useState(false);
  const [matchDetailsPanelActive, setMatchDetailsPanelActive] = useState(false);
  const [profilePanelActive, setProfilePanelActive] = useState(false);
  const [
    detailsTitle,
    detailsText,
    viewProfileText,
    cancelText,
    cancelConfirmTitle,
    cancelConfirmPrompt,
    cancelConfirmYes,
    cancelConfirmNo,
  ] = useCopy([
    "messageCenter.matchDetails.detailsTitle",
    "messageCenter.matchDetails.detailsLink",
    "messageCenter.matchDetails.viewProfile",
    "messageCenter.matchDetails.cancelMatch",
    "messageCenter.cancelConfirm.title",
    "messageCenter.cancelConfirm.prompt",
    "messageCenter.cancelConfirm.yes",
    "messageCenter.cancelConfirm.no",
  ]);
  useOnClickOutside(dropdownRef, () => {
    setDropdwonActive(false);
  });

  const handleCancel = async () => {
    await cancelItem(item);
    setShowCancelConfirm(false);
  };

  if (!item) {
    return (
      <Wrapper>
        <BackButton onClick={onBack}></BackButton>
      </Wrapper>
    );
  }

  const showDropdown = item.active || item.type === "match";

  return (
    <>
      <Wrapper>
        <BackButton onClick={onBack}></BackButton>
        <PhotoWrapper>
          <Photo item={item} />
        </PhotoWrapper>
        <TextWrapper>
          <NameWrapper onClick={() => showDropdown && setDropdwonActive(true)}>
            <Name>{item.title}</Name>
            {showDropdown && <img alt="dropdown" src={dropdownArrow} />}
          </NameWrapper>
          <DetailsWrapper>
            <Details>
              {getRelationship(item)}
              <DetailsLink onClick={() => setMatchDetailsPanelActive(true)}>
                {detailsText}
              </DetailsLink>
            </Details>
            <StatusWrapper>
              <Status status={item.status} />
            </StatusWrapper>
          </DetailsWrapper>
        </TextWrapper>
        {dropdownActive && (
          <Dropdown ref={dropdownRef}>
            {item.type === "match" && (
              <DropdownItem
                onClick={() => {
                  setDropdwonActive(false);
                  setProfilePanelActive(true);
                }}
              >
                {swapCopyVariables(viewProfileText, { NAME: item.title })}
              </DropdownItem>
            )}
            {item.active && (
              <DropdownItem
                onClick={() => {
                  setDropdwonActive(false);
                  setShowCancelConfirm(true);
                }}
              >
                {cancelText}
              </DropdownItem>
            )}
          </Dropdown>
        )}
        <Slideout
          active={matchDetailsPanelActive}
          title={detailsTitle}
          onClose={() => setMatchDetailsPanelActive(false)}
        >
          <ItemPanel item={item} />
        </Slideout>
        <Slideout
          active={profilePanelActive}
          onClose={() => setProfilePanelActive(false)}
        >
          TODO: Profile Panel
        </Slideout>
      </Wrapper>
      {showCancelConfirm && (
        <ConfirmWrapper>
          <ConfirmOverlay />
          <ConfirmBox>
            <ConfirmTitle>
              <ConfirmIcon>
                <ErrorExclamation />
              </ConfirmIcon>
              {cancelConfirmTitle}
            </ConfirmTitle>
            <ConfirmPrompt>{cancelConfirmPrompt}</ConfirmPrompt>
            <ConfirmButtonWrapper>
              <ConfirmButton onClick={() => setShowCancelConfirm(false)}>
                {cancelConfirmNo}
              </ConfirmButton>
              <ConfirmButton onClick={handleCancel}>
                {cancelConfirmYes}
              </ConfirmButton>
            </ConfirmButtonWrapper>
          </ConfirmBox>
        </ConfirmWrapper>
      )}
    </>
  );
};

export default ItemHeader;
