import {
  useContext,
  useEffect,
  createContext,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { Relationships, Statuses } from "@sussex/match-utils";
import {
  getTherapist,
  getRequests,
  getMatches,
  getMessages,
  markMatchRead,
  cancelRequest,
  getMatch,
} from "../../httpapi";
import { decryptText } from "../../idp";
import { UserContext } from "../UserProvider";
import useCopy from "../../hooks/useCopy";
import blurredProfile from "../../assets/blurred-therapist.png";

const maxMessageResponseLength = 10;

export const ItemContext = createContext(null);

const getPhotoUrl = id => {
  return `${process.env.REACT_APP_BASE_DIRECTORY_API_URL}/profile-photo/${id}`;
};

const therapists = {};

const getTherapistInfo = async id => {
  if (therapists[id]) {
    return therapists[id];
  }
  const therapist = await getTherapist(id);
  therapist.photoUrl = getPhotoUrl(id);
  therapists[id] = therapist;
  return therapist;
};

const isActiveRequest = r => r.status === "open";

const isActiveMatch = m =>
  !m.isBlocked &&
  [Statuses.accepted, Statuses.confirmed, Statuses.scheduled].includes(
    m.status,
  );

const isVisibleMatch = m =>
  !m.isBlocked &&
  [
    Statuses.accepted,
    Statuses.confirmed,
    Statuses.scheduled,
    Statuses.refused,
  ].includes(m.status);

const isPartialMatch = m => m.requestId && !m.clientUuid;

const unifyItems = async (items, requestTitle) => {
  const unifiedItems = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (!item.type) {
      if (item.requestId) {
        // item is a match
        if (!isPartialMatch(item)) {
          item.type = "match";

          const therapist = await getTherapistInfo(item.profileId);
          if (therapist) {
            item.title = therapist.fullName;
            item.therapist = therapist;
            item.photo = therapist.photoUrl;
          }
        }
      } else {
        // item is a request
        item.type = "request";
        item.title = requestTitle;
        item.photo = blurredProfile;
      }
    }

    if (item.type === "match") {
      item.date = item.lastActivityTimestamp / (1000 * 1000);
      item.active = isActiveMatch(item);

      if (!item.location) {
        // get contact details from the request if they are not present
        const req = items.find(r => r.id === item.requestId);

        if (req) {
          item.name = req.name;
          item.email = req.email;
          item.phoneNumber = req.phoneNumber;
          item.location = req.location;
        }
      }

      if (isVisibleMatch(item)) {
        unifiedItems.push(item);
      }
    }

    if (item.type === "request") {
      item.date = item.createdAt * 1000;
      item.active = isActiveRequest(item);

      // only show requests in the following cases:
      // - if the request is active and there are no active matches related to it
      // - if the request is closed and there are no visible matches related to it
      const activeMatch = items.find(
        m => m.requestId === item.id && isActiveMatch(m),
      );

      const visibleMatch = items.find(
        m => m.requestId === item.id && isVisibleMatch(m),
      );

      if (
        (isActiveRequest(item) && !activeMatch) ||
        (!isActiveRequest(item) && !visibleMatch)
      ) {
        unifiedItems.push(item);
      }
    }
  }

  return unifiedItems;
};

// Filter duplicates and sort by id asc
const filterAndSortMessages = messages =>
  messages
    .filter((m, i, a) => {
      if (!m?.id) {
        return false;
      }
      return a.findIndex(m2 => m2.id === m.id) === i;
    })
    .sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));

const ItemProvider = ({ children }) => {
  const [rawItems, setRawItems] = useState(null);
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  const { client, user, signedIn } = useContext(UserContext);
  const [matchRequest, forText, childText, coupleText] = useCopy([
    "messageCenter.matchRequest",
    "messageCenter.matchDetails.for",
    "messageCenter.matchDetails.child",
    "messageCenter.matchDetails.couple",
  ]);

  // we need to use a useLayoutEffect instead of a useEffect here
  // to ensure that effects are executed synchronously when rawItems
  // changes, because we update rawItems from here as well in some cases
  useLayoutEffect(() => {
    if (!rawItems) {
      return;
    }

    let canceled = false;
    const cancelEffect = () => {
      canceled = true;
    };

    // check if there are items with partial data and try to populate them
    const partialMatches = rawItems.filter(
      item => isPartialMatch(item) && !item.populated && !item.isBlocked,
    );

    if (partialMatches.length > 0) {
      const fullItems = rawItems.filter(
        item => !partialMatches.find(it => it.id === item.id),
      );
      const populate = async () => {
        const populateItem = async item => {
          item.populated = true;
          const res = await getMatch(item.requestId, item.id);
          if (res.success) {
            Object.assign(item, res.match);
          }
        };
        const promises = partialMatches.map(populateItem);
        await Promise.all(promises);
        if (canceled) {
          // skip changing the state if the effect was
          // canceled by another state change while we
          // were processing the async stuff
          return;
        }
        setRawItems([...fullItems, ...partialMatches]);
      };
      populate();
      return cancelEffect;
    }

    // unify items once they are all populated
    const unify = async () => {
      const unifiedItems = await unifyItems(rawItems, matchRequest);
      if (canceled) {
        // skip changing the state if the effect was
        // canceled by another state change while we
        // were processing the async stuff
        return;
      }
      setItems(unifiedItems);
      setLoadingItems(false);
    };
    unify();

    return cancelEffect;
  }, [rawItems, matchRequest]);

  useEffect(() => {
    if (!signedIn) {
      return;
    }
    const l = async () => {
      const requestPromise = getRequests();
      const matchPromise = getMatches();
      const [r, m] = await Promise.all([requestPromise, matchPromise]);
      setRawItems([...r.items, ...m.items]);
    };
    l();
  }, [signedIn]);

  const updateItem = useCallback(
    ({ item, updateActivity = false, itemId = null }) => {
      if (itemId) {
        const oldData = rawItems.find(({ id }) => id === itemId) || {};
        item = {
          ...oldData,
          ...item,
          populated: false,
        };
      }

      if (updateActivity && item.type === "match") {
        const now = new Date().getTime();
        item.lastActivityTimestamp = now * 1000 * 1000;
        item.date = now;
      }

      setRawItems(h => {
        const newItems = h.filter(({ id }) => id !== item.id);
        return [...newItems, item];
      });
    },
    [rawItems],
  );

  const readMatch = useCallback(
    async item => {
      if (!item || item.type === "request" || !item.newActivityForClient) {
        return;
      }
      await markMatchRead(item);
      item.newActivityForClient = false;
      updateItem({ item });
    },
    [updateItem],
  );

  const updateStatus = useCallback(
    (item, status) => {
      item.status = status;
      if (item.type === "match") {
        const now = new Date().getTime();
        item.date = now;
        item.lastStatusChangeTimestamp = Math.floor(now / 1000);
      }
      updateItem({ item, updateActivity: true });
    },
    [updateItem],
  );

  const addPendingMessage = useCallback(
    (item, tmpId, decrypted) => {
      if (!item.messages) {
        item.messages = [];
      }
      item.messages.push({
        id: tmpId,
        channelId: item.id,
        type: "match",
        createdAt: Math.floor(new Date().getTime() / 1000),
        senderId: user.uuid,
        senderType: "client",
        decrypted,
        metadata: {
          requestId: item.requestId,
        },
      });
      updateItem({ item });
    },
    [user.uuid, updateItem],
  );

  const confirmPendingMessage = useCallback(
    (item, tmpId, id, encrypted) => {
      item.messages.forEach((m, i) => {
        if (m.id === tmpId) {
          item.messages[i].id = id;
          item.messages[i].text = encrypted;
        }
      });
      item.lastMessage = encrypted;
      updateItem({ item, updateActivity: true });
    },
    [updateItem],
  );

  const refusePendingMessage = useCallback(
    (item, tmpId) => {
      item.messages.forEach((m, i) => {
        if (m.id === tmpId) {
          item.messages[i].error = true;
        }
      });
      updateItem({ item });
    },
    [updateItem],
  );

  const decryptMessage = useCallback(
    async m => {
      const decrypted = await decryptText(client, m);
      return decrypted;
    },
    [client],
  );

  const updateMessages = useCallback(
    (item, messages) => {
      item.messages = filterAndSortMessages(messages);
      updateItem({ item });
    },
    [updateItem],
  );

  const addMessage = useCallback(
    async (itemId, message) => {
      message.decrypted = await decryptMessage(message.text);
      const item = rawItems.find(({ id }) => id === itemId);
      if (!item) {
        return;
      }
      const oldMesages = item.messages || [];
      item.messages = filterAndSortMessages([...oldMesages, message]);
      updateItem({ item });
    },
    [rawItems, updateItem, decryptMessage],
  );

  const loadMessages = useCallback(
    async item => {
      if (item.type !== "match") {
        return [];
      }

      // Get latest page of messages
      const response = await getMessages({
        channelId: item.id,
      });
      if (!response.success) {
        return [];
      }
      const messageItems = response.items;

      item.hasMoreMessages = messageItems.length === maxMessageResponseLength;

      // decrypt any new messages
      const decrypt = async m => {
        if (m.decrypted) {
          return;
        }
        try {
          const decrypted = await decryptMessage(m.text);
          m.decrypted = decrypted;
        } catch (e) {
          console.error("error decrypting message", e);
        }
      };
      const promises = messageItems.map(decrypt);
      await Promise.all(promises);
      updateMessages(item, messageItems);
    },
    [decryptMessage, updateMessages],
  );

  const loadPrevMessages = useCallback(
    async item => {
      if (item.type !== "match") {
        return [];
      }

      const firstMessageId = item.messages?.[0]?.id;
      if (!firstMessageId || !item.hasMoreMessages) {
        return;
      }
      const response = await getMessages({
        channelId: item.id,
        endMessageId: firstMessageId,
      });
      if (!response.success) {
        return;
      }
      const messageItems = response.items;
      item.hasMoreMessages = messageItems.length === maxMessageResponseLength;

      const decryptedMessages = [...item.messages];
      const decryptedMessageIds = decryptedMessages.map(({ id }) => id);
      const decrypt = async m => {
        if (decryptedMessageIds.includes(m.id)) {
          return;
        }
        try {
          const decrypted = await decryptMessage(m.text);
          m.decrypted = decrypted;
          decryptedMessages.push(m);
        } catch (e) {
          console.error("error decrypting message", e);
        }
      };
      const promises = messageItems.map(decrypt);
      await Promise.all(promises);
      updateMessages(item, decryptedMessages);
    },
    [decryptMessage, updateMessages],
  );

  const cancelItem = useCallback(
    async item => {
      const requestId = item.type === "request" ? item.id : item.requestId;
      const res = await cancelRequest({ requestId });
      if (res.success) {
        const request = rawItems.find(i => i.id === requestId);
        updateStatus(request, Statuses.canceled);

        const acceptedMatches = rawItems.filter(
          i => i.requestId === requestId && i.status === Statuses.accepted,
        );
        acceptedMatches.forEach(m => {
          updateStatus(m, Statuses.canceled);
        });
      }
    },
    [updateStatus, rawItems],
  );

  const getRelationship = item => {
    if (!item) {
      return "";
    }
    const r = item.preferences?.relationship?.[0]?.value;
    if (r === Relationships.else) {
      const name = item.preferences?.["other-relationship-name"]?.[0]?.value;
      return `${forText} ${name}`;
    }
    if (r === Relationships.child) {
      return `${forText} ${item.name}, ${childText}`;
    }
    if (r === Relationships.couple) {
      return `${forText} ${item.name}, ${coupleText}`;
    }
    return `${forText} ${item.name}`;
  };

  return (
    <ItemContext.Provider
      value={{
        items,
        loadingItems,
        readMatch,
        updateItem,
        updateStatus,
        addMessage,
        addPendingMessage,
        confirmPendingMessage,
        refusePendingMessage,
        loadMessages,
        loadPrevMessages,
        getRelationship,
        cancelItem,
        decryptMessage,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};

export default ItemProvider;
