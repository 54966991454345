import styled from "styled-components";
import { H2, Button, TextInput, ErrorBox } from "@sussex/react-kit/elements";

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PrimaryHeading = styled(H2)`
  font-size: 26px;
`;

export const Heading = styled(H2)`
  font-size: 26px;
  margin-bottom: 24px;
`;

export const Body = styled.div`
  padding: 20px 25px;
`;
export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px 25px;
`;

export const Form = styled.form`
  flex: 1;
`;

export const Container = styled.div`
  flex: 1;
`;

export const Submit = styled(Button)`
  width: 100%;
  height: 56px;
`;

export const TextField = styled(TextInput)`
  margin-bottom: 16px;
`;

const ErrorWrapper = styled.div`
  margin-bottom: 16px;
`;
const ErrorText = styled.div`
  margin: 0 15px;
`;
export const ErrorMessage = ({ children }) => {
  return (
    <ErrorWrapper>
      <ErrorBox>
        <ErrorText>{children}</ErrorText>
      </ErrorBox>
    </ErrorWrapper>
  );
};
