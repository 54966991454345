import { useRef } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import closeIcon from "../../assets/close.svg";

const TRANSITION_TIME = 400;
const TRANSITION_NAME = "slideout";

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 24, 39, 0.5);
  z-index: 10;
`;

const Panel = styled.div`
  position: fixed;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 16px 16px 0px 0px;

  transition: transform ${TRANSITION_TIME}ms ease-in-out;
  transform: translateY(100%);

  &.${TRANSITION_NAME}-enter {
    transform: translateY(100%);
  }

  &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-enter-done {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-exit {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-exit-active {
    transform: translateY(100%);
  }

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    top: 0;
    left: auto;
    width: 500px;
    border: none;
    box-shadow: 0px 0px 30px rgba(31, 41, 55, 0.15);
    border-radius: 0;
    transform: translateX(100%);

    &.${TRANSITION_NAME}-enter {
      transform: translateX(100%);
    }

    &.${TRANSITION_NAME}-enter-active {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-enter-done {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-exit {
      transform: translateX(0);
    }

    &.${TRANSITION_NAME}-exit-active {
      transform: translateX(100%);
    }
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  padding: 34px 24px;
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 31px;
  right: 24px;

  &:after {
    content: "";
    background-image: url("${closeIcon}");
    background-repeat: no-repeat;
    background-position: center center;
    inset: 0;
    background-size: 14px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    top: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const Slideout = ({ active, title, onClose, children }) => {
  const nodeRef = useRef(null);

  return (
    <>
      {active && <Overlay />}
      <CSSTransition
        nodeRef={nodeRef}
        in={active}
        timeout={TRANSITION_TIME}
        classNames={TRANSITION_NAME}
        unmountOnExit
      >
        <Panel ref={nodeRef}>
          <Header>
            {title && <Title>{title}</Title>}
            <CloseButton type="button" onClick={onClose} />
          </Header>
          {children}
        </Panel>
      </CSSTransition>
    </>
  );
};

export default Slideout;
