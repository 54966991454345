import { useState, useEffect, useContext, useMemo } from "react";
import styled, { css, useTheme } from "styled-components";

import { swapCopyVariables } from "@sussex/react-kit/utils";
import { useWindowSize } from "@sussex/react-kit/hooks";
import { H2 } from "@sussex/react-kit/elements";
import Photo from "../Photo";
import { ItemContext } from "../../providers/ItemProvider";

import useCopy from "../../hooks/useCopy";
import useInterval from "../../hooks/useInterval";
import Status from "../ItemStatus";

const Header = styled(H2)`
  margin: 10px;
`;

const ItemList = styled.div`
  margin-top: 16px;
`;

const MatchItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  padding: 16px 8px;
  gap: 7px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.light};
    `}

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

const DetailsWrapper = styled.div`
  overflow: hidden;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

const BadgeWrapper = styled.div`
  width: 30px;
`;

const Badge = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primaryBlue};
  width: 10px;
  height: 10px;
`;

const PhotoWrapper = styled.div`
  flex: 0 0 auto;
`;

const Name = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
`;

const Relationship = styled.p`
  margin: 0;
  padding: 0 0 5px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Updated = styled.p`
  margin: 0;
  padding: 0 0 5px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClosedTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.secondary};
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  width: 30px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin: 0 10px;
`;

const Item = ({ item, active, onSelect }) => {
  const { getRelationship } = useContext(ItemContext);
  const [createdText, updatedText, todayText] = useCopy([
    "messageCenter.request.created",
    "messageCenter.request.updated",
    "messageCenter.match.updatedToday",
  ]);
  const [now, setNow] = useState(new Date());

  useInterval(() => {
    setNow(new Date());
  }, 60000);

  const getDate = ts => {
    const nowStr = now.toLocaleDateString(navigator.language, {
      day: "numeric",
      month: "long",
    });

    const updated = new Date(ts);
    const updatedStr = updated.toLocaleDateString(navigator.language, {
      day: "numeric",
      month: "long",
    });

    if (nowStr === updatedStr) {
      return todayText;
    }
    return updatedStr;
  };

  const updatedCopy = item.type === "request" ? createdText : updatedText;

  return (
    <MatchItem active={active} onClick={() => onSelect(item.id)}>
      <DetailsWrapper>
        <BadgeWrapper>{item.newActivityForClient && <Badge />}</BadgeWrapper>
        <PhotoWrapper>
          <Photo item={item} />
        </PhotoWrapper>
        <div style={{ width: "100%" }}>
          <NameWrapper>
            <Name>{item.title}</Name>
            <Status status={item.status} />
          </NameWrapper>
          <Relationship>{getRelationship(item)}</Relationship>
          <Updated>
            {swapCopyVariables(updatedCopy, { DATE: getDate(item.date) })}
          </Updated>
        </div>
      </DetailsWrapper>
    </MatchItem>
  );
};

const List = ({ items, selectedId, onSelect }) => {
  const [titleText, closedRequestsText] = useCopy([
    "messageCenter.messages",
    "messageCenter.closedRequests",
  ]);

  const sortedItems = useMemo(
    () => items.sort((a, b) => b.date - a.date),
    [items],
  );

  const activeItems = useMemo(
    () => sortedItems.filter(r => r.active),
    [sortedItems],
  );

  const closedItems = useMemo(
    () => sortedItems.filter(r => !r.active),
    [sortedItems],
  );

  // Auto-select first match on desktop. Assumes match history is sorted.
  const { breakpointsInt } = useTheme();
  const { width } = useWindowSize();
  useEffect(() => {
    const isDesktop = width >= breakpointsInt[">mobile"];
    if (isDesktop && !selectedId) {
      const match = activeItems?.[0] || closedItems?.[0] || null;
      if (match) {
        setTimeout(() => {
          onSelect(match.id);
        });
      }
    }
  }, [width, activeItems, closedItems, onSelect, selectedId, breakpointsInt]);

  return (
    <>
      <Header>{titleText}</Header>
      <ItemList>
        {activeItems.map(r => (
          <Item
            item={r}
            key={r.id}
            active={r.id === selectedId}
            onSelect={onSelect}
          />
        ))}
        {closedItems.length ? (
          <ClosedTitle>
            <Line />
            {closedRequestsText}
            <Line />
          </ClosedTitle>
        ) : null}
        {closedItems.map(r => (
          <Item
            item={r}
            key={r.id}
            active={r.id === selectedId}
            onSelect={onSelect}
          />
        ))}
      </ItemList>
    </>
  );
};

export default List;
