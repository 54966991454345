import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  background-color: white;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex: 1 0 auto;
    margin: 0 auto;
    width: calc(60%);
    min-width: 800px;
    max-width: 900px;
    position: absolute;
    top: 116px;
    right: 0;
    left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-radius: 16px;
    min-height: 600px;
  }
`;

const PanelWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PanelWrapper;
