import { useEffect, useState } from "react";
import styled from "styled-components";
import { InfoBox, ErrorBox, Pill } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import SurveyTemplate from "./shared/SurveyTemplate";
import useCopy from "../../hooks/useCopy";
import { tokenExpandSearch } from "../../httpapi";

const Text = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const LoginLink = styled.a`
  display: block;
  margin: 16px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const Pills = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    grid-template-columns: 1fr 1fr;
  }
  margin-bottom: 16px;
`;

const ExpandSearch = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [token, setToken] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const [
    title,
    cancelMessage,
    submitText,
    yes,
    no,
    continueSuccess,
    cancelSuccess,
    duplicateText,
    genericError,
    errorTitle,
    backHome,
  ] = useCopy([
    "messageCenter.matchRequest",
    "messageCenter.cancelConfirm.prompt",
    "button.submit",
    "client.feedback.cancelSearch.yes",
    "client.feedback.cancelSearch.no",
    "client.feedback.searchContinues",
    "client.feedback.searchCancelled",
    "client.confirmLanding.duplicateConfirmation",
    "client.confirmLanding.genericError",
    "client.confirmLanding.errorTitle",
    "client.feedback.backToHome",
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const t = params.get("token");
    const expandSearch = params.get("expand-search");

    if (!t) {
      setError("generic");
      setLoading(false);
      return;
    }

    setToken(t);

    if (expandSearch !== "yes") {
      setLoading(false);
      return;
    }

    const load = async () => {
      const res = await tokenExpandSearch({ token: t, expandSearch: true });
      if (!res.success) {
        setError(res.conflict ? "conflict" : "generic");
      }
      setLoading(false);
    };

    load();
  }, []);

  const handleClick = async () => {
    setSubmitted(true);
    setLoading(true);
    const res = await tokenExpandSearch({
      token,
      expandSearch: response === "continue",
    });
    if (!res.success) {
      setError(res.conflict ? "conflict" : "generic");
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <SurveyTemplate title={title}>
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </SurveyTemplate>
    );
  }

  if (error) {
    const errorText = {
      generic: genericError,
      conflict: duplicateText,
    };

    return (
      <SurveyTemplate title={errorTitle}>
        <ErrorBox>{errorText[error]}</ErrorBox>
        <LoginLink href="/">{backHome}</LoginLink>
      </SurveyTemplate>
    );
  }

  if (submitted) {
    const successMessage =
      response === "continue" ? continueSuccess : cancelSuccess;
    return (
      <SurveyTemplate title={title}>
        <InfoBox>{successMessage}</InfoBox>
        <LoginLink href="/">{backHome}</LoginLink>
      </SurveyTemplate>
    );
  }

  return (
    <SurveyTemplate
      title={title}
      onSubmit={handleClick}
      submitText={submitText}
      submitDisabled={!response}
    >
      <Text>{cancelMessage}</Text>
      <Pills>
        <Pill
          selected={response === "cancel"}
          onClick={() => setResponse("cancel")}
        >
          {yes}
        </Pill>
        <Pill
          selected={response === "continue"}
          onClick={() => setResponse("continue")}
        >
          {no}
        </Pill>
      </Pills>
    </SurveyTemplate>
  );
};

export default ExpandSearch;
