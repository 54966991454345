import { useState, useRef, useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../providers/UserProvider";
import useCopy from "../../hooks/useCopy";
import { getInitials } from "@sussex/react-kit/utils";
import { useOnClickOutside } from "@sussex/react-kit/hooks";

import Header from "./Header";

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const Name = styled.p`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  margin: 0 8px 0 0;
  display: none;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: block;
  }
`;

const Badge = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #679186;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 1;
`;

const LogoutWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border-radius: 8px;
  min-width: 200px;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  padding: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  background-color: white;
  color: ${({ theme }) => theme.colors.default};
  z-index: 1;
`;

const AuthenticatedHeader = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { signOut, user, signedIn } = useContext(UserContext);
  const userInfoRef = useRef(null);
  const [logoutText] = useCopy(["client.panelHeader.logoutLink"]);

  useOnClickOutside(userInfoRef, () => setShowLogout(false));

  const toggleLogout = e => {
    e.stopPropagation();
    setShowLogout(!showLogout);
  };
  const userInfo = () => {
    return (
      <UserInfo ref={userInfoRef} onClick={toggleLogout}>
        {signedIn && (
          <>
            <Name>{user.name}</Name>
            <Badge>{getInitials(user.name)}</Badge>
            {showLogout && (
              <LogoutWrapper onClick={signOut}>{logoutText}</LogoutWrapper>
            )}
          </>
        )}
      </UserInfo>
    );
  };

  return <Header right={userInfo} />;
};

export default AuthenticatedHeader;
