import { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import ItemHeader from "./ItemHeader";
import ChatInput from "../Chat/ChatInput";
import { ItemContext } from "../../providers/ItemProvider";
import useIsActive from "../../hooks/useIsActive";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const InputWrapper = styled.div`
  justify-self: flex-end;
  flex: 0 0 auto;
`;

const DetailWrapper = ({
  item,
  children,
  setShowCancelConfirm,
  showCancelConfirm,
}) => {
  const { readMatch } = useContext(ItemContext);
  const { lastActivityDate } = useIsActive();
  const working = useRef(false);

  useEffect(() => {
    // this effect is executed on every second when there's
    // user activity on the item, so be careful if you are
    // updating this logic
    if (
      !item ||
      item.type !== "match" ||
      !item.newActivityForClient ||
      !lastActivityDate ||
      working.current
    ) {
      return;
    }

    working.current = true;

    const markItemAsViewed = async () => {
      await readMatch(item);
      working.current = false;
    };

    markItemAsViewed();
  }, [lastActivityDate, item, readMatch]);

  return (
    <Wrapper>
      <ItemHeader
        item={item}
        showCancelConfirm={showCancelConfirm}
        setShowCancelConfirm={setShowCancelConfirm}
      />
      <Content>{children}</Content>
      <InputWrapper>
        <ChatInput item={item} />
      </InputWrapper>
    </Wrapper>
  );
};

export default DetailWrapper;
