import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../hooks/useCopy";
import { BackgroundImage } from "@sussex/react-kit/elements";
import Header from "../components/Header";
import { ErrorExclamation } from "@sussex/react-kit/assets";

const Panel = styled.div`
  background: #fff;
  border: 1px solid #000;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex-direction: row;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Account for border radius: */
  padding-bottom: 10px;
  margin-bottom: -10px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding-bottom: 0;
    margin-bottom: 0;
    height: 100%;
  }
`;

const PanelWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex: 1 0 auto;
    ${({ center }) =>
      center &&
      css`
        margin: 0 auto;
        width: calc(60%);
        min-width: 800px;
        max-width: 900px;
        position: absolute;
        top: 88px;
        right: 0;
        left: 0;
      `}
  }
`;

const TextWrapper = styled.div`
  padding: 20px;
  display: flex;
  min-height: 40px;
  align-items: center;

  > * {
    margin: 0 10px;
  }

  h3 {
    margin: 0;
    padding-bottom: 5px;
  }
  p {
    margin: 0;
  }
`;

const Login = styled.span`
  cursor: pointer;
`;

const Link = () => {
  const navigate = useNavigate();
  const [linkText] = useCopy(["client.clientError.loginLink"]);
  return <Login onClick={() => navigate("/requests")}>{linkText}</Login>;
};

export default function NoutFoundView() {
  const [title, body] = useCopy([
    "client.clientError.pageNotFoundTitle",
    "client.clientError.pageNotFoundBody",
  ]);

  return (
    <Wrapper>
      <ContentWrapper>
        <BackgroundWrapper>
          <BackgroundImage url="/backgrounds/full/sitting-room.jpg" />
          <Header />
        </BackgroundWrapper>
        <PanelWrapper center>
          <Panel center>
            <TextWrapper>
              <ErrorExclamation />
              <div>
                <h3>{title}</h3>
                <p>{swapCopyVariables(body, { LINK: <Link /> })}</p>
              </div>
            </TextWrapper>
          </Panel>
        </PanelWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}
