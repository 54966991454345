import styled from "styled-components";
import closeIcon from "../../assets/close.svg";
import backArrow from "../../assets/back-arrow.svg";
import BaristaGirl from "../../assets/therapist-illustration.png";
import { H1 } from "@sussex/react-kit/elements";

const Illustration = styled.img`
  pointer-events: none;
  width: 40px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 72px;
  }
`;

const Content = styled.div`
  margin-left: 16px;
  max-width: 330px;
`;

const Btn = styled.button`
  appearance: none;
  background-color: white;
  border: none;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 40px;
  height: 40px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding: 0;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.border};
    &:hover {
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    }
  }
`;

const EmptyBtn = styled.div`
  width: 40px;
  height: 40px;
`;

const CloseButton = styled(Btn)`
  background-image: url("${closeIcon}");
  background-size: 14px 14px;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    visibility: hidden;
  }
`;

const BackArrow = styled(Btn)`
  background-image: url("${backArrow}");
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding: 32px 24px;
  }
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = ({ title, back, close = true }) => {
  return (
    <Wrapper>
      {back !== null ? (
        <BackArrow onClick={back} type="button" />
      ) : (
        <EmptyBtn />
      )}
      <MainContent>
        <Illustration src={BaristaGirl} alt={title} />
        <Content>
          <H1>{title}</H1>
        </Content>
      </MainContent>
      {close ? <CloseButton type="button" /> : <EmptyBtn />}
    </Wrapper>
  );
};
export default Header;
