import { useContext, useMemo, useCallback } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import List from "./List";
import ItemDetail from "../ItemDetail";
import { ItemContext } from "../../providers/ItemProvider";
import { Loading } from "@sussex/react-kit/assets";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    height: 80vh;
    box-shadow: 0px 15px 30px rgba(31, 41, 55, 0.25);
    border-radius: 16px;
  }
`;

const ListWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 42%;
  }
`;

const ListContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ListViewWrapper = styled.div`
  padding: 24px 8px 16px 8px;
  overflow: auto;
`;

const DetailWrapper = styled.div`
  width: 58%;
  box-shadow: 0px 15px 30px rgba(31, 41, 55, 0.25);
  border-left: 1px solid ${({ theme }) => theme.colors.border};

  ${({ theme }) => theme.breakpoints["mobile"]} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ItemListView = ({ items, selectedId }) => {
  const navigate = useNavigate();

  const onSelect = useCallback(
    newId => {
      setTimeout(() => {
        navigate(`/requests/${newId}`);
      });
    },
    [navigate],
  );

  return (
    <ListViewWrapper>
      <List items={items} selectedId={selectedId} onSelect={onSelect} />
    </ListViewWrapper>
  );
};

const ItemList = () => {
  const { id } = useParams();
  const { items, loadingItems } = useContext(ItemContext);

  const item = useMemo(() => items.find(i => i.id === id), [items, id]);

  return (
    <Wrapper>
      {loadingItems ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <>
          <ListWrapper>
            <ListContent>
              <ItemListView items={items} selectedId={id} />
            </ListContent>
          </ListWrapper>
          <DetailWrapper active={!!id}>
            {item ? <ItemDetail item={item} /> : <div />}
          </DetailWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default ItemList;
