import { useState } from "react";
import styled from "styled-components";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  InfoBox,
  Passcode,
  Link,
  PasswordStrength,
} from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import {
  Container,
  Form,
  Heading,
  TextField,
  ErrorMessage,
  Submit,
  Body,
  Footer,
} from "./Elements";
import useCopy from "../../hooks/useCopy";
import { obfuscateEmail } from "../../utils";

const BoxWrapper = styled.div`
  margin-bottom: 24px;
`;

const LinkText = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.normal};
  margin-top: 24px;
`;

const ResetPassword = ({ email, onBack }) => {
  const [
    titleText,
    messageText,
    passcodeBodyText,
    passcodeResendText,
    passcodeResendLinkText,
    newPasswordText,
    confirmPasswordText,
    passwordStrengthLengthText,
    passwordStrengthUppercaseText,
    passwordStrengthNumberText,
    submitActionText,
    backActionText,
    confirmText,
    passwordErrorText,
    codeErrorText,
    generalErrorText,
    sendCodeErrorText,
  ] = useCopy([
    "client.resetPassword.title",
    "client.resetPassword.message",
    "client.resetPassword.passcodeBody",
    "client.resetPassword.passcodeResendText",
    "client.resetPassword.passcodeResendLink",
    "client.resetPassword.newPassword",
    "client.resetPassword.confirmPassword",
    "client.resetPassword.passwordStrengthLength",
    "client.resetPassword.passwordStrengthUppercase",
    "client.resetPassword.passwordStrengthNumber",
    "client.resetPassword.submitAction",
    "client.resetPassword.backAction",
    "client.resetPassword.confirm",
    "client.resetPassword.passwordError",
    "client.resetPassword.codeError",
    "client.resetPassword.generalError",
    "client.forgotPassword.sendCodeError",
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetComplete, setResetComplete] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const userData = {
    Username: email,
    Pool: userPool,
    Storage: MemoryStorage,
  };

  const cognitoUser = new CognitoUser(userData);

  const handleSubmit = e => {
    e.preventDefault();
    setError(false);

    if (password !== confirmPassword) {
      setError(passwordErrorText);
      return;
    }

    setLoading(true);

    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        setResetComplete(true);
      },
      onFailure: err => {
        setLoading(false);

        if (err) {
          if (err.code === "CodeMismatchException") {
            setError(codeErrorText);
          } else {
            setError(generalErrorText);
          }
        }
      },
    });
  };

  const resendCode = () =>
    new Promise(resolve => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          return resolve();
        },
        onFailure: err => {
          console.error(err);
          setError(sendCodeErrorText);
          return resolve();
        },
      });
    });

  const obfuscatedEmail = obfuscateEmail(email);

  return (
    <Container>
      <Body>
        <Heading>{titleText}</Heading>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Body>
      {resetComplete ? (
        <Body>
          <InfoBox>
            <span>{confirmText}</span>
          </InfoBox>
        </Body>
      ) : (
        <>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Body>
              <BoxWrapper>{messageText}</BoxWrapper>
              <BoxWrapper>
                <Passcode
                  obfuscatedEmail={obfuscatedEmail}
                  onChange={setCode}
                  resend={resendCode}
                  loading={loading}
                  enterPasscodeBody={passcodeBodyText}
                  resendText={passcodeResendText}
                  resendLinkText={passcodeResendLinkText}
                  unlockIconVisible={false}
                />
              </BoxWrapper>
              <TextField
                label={newPasswordText}
                value={password}
                type="password"
                onChange={({ target }) => setPassword(target.value)}
                disabled={loading}
                autoComplete="new-password"
              />
              <TextField
                label={confirmPasswordText}
                value={confirmPassword}
                type="password"
                onChange={({ target }) => setConfirmPassword(target.value)}
                disabled={loading}
                autoComplete="new-password"
              />
              <BoxWrapper>
                <PasswordStrength
                  password={password}
                  setValid={setValidPassword}
                  lengthText={passwordStrengthLengthText}
                  uppercaseText={passwordStrengthUppercaseText}
                  numberText={passwordStrengthNumberText}
                />
              </BoxWrapper>
              <LinkText onClick={onBack}>{backActionText}</LinkText>
            </Body>
            <Footer>
              <Submit
                type="submit"
                disabled={code.length < 6 || !validPassword}
              >
                {loading ? <Loading /> : submitActionText}
              </Submit>
            </Footer>
          </Form>
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
