import { useState } from "react";
import styled from "styled-components";
import { Button, TextArea } from "@sussex/react-kit/elements";
import { useNavigate } from "react-router-dom";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div``;

const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 24px;
`;

const Text = styled.div`
  margin-bottom: 24px;
`;

const TextInput = styled(TextArea)`
  margin-bottom: 24px;
  border-radius: 5px;
  resize: none;
`;

const RefuseWorkflow = ({ setWorkflowActive, nextItem, refuseFeedback }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [working, setWorking] = useState(false);
  const [
    whyDeclineText,
    nextText,
    nextMatchText,
    continueSearchingText,
    continueSearchText,
    stopSearchText,
    errorText,
  ] = useCopy([
    "client.feedback.declineReason",
    "client.steps.next",
    "client.feedback.nextMatch",
    "client.feedback.continueSearch",
    "client.feedback.continueSearch.yes",
    "client.feedback.continueSearch.no",
    "survey.errorMsg",
  ]);
  const [showContinueMatching, setShowContinueMatching] = useState(false);
  const navigate = useNavigate();

  const next = async () => {
    setWorking(true);
    setError(false);
    const res = await refuseFeedback({ message });
    setWorking(false);
    if (!res.success) {
      setError(true);
      return;
    }
    if (nextItem) {
      setWorkflowActive(false);
      navigate(`/requests/${nextItem.id}`);
      return;
    }
    setShowContinueMatching(true);
  };

  const continueSearching = async c => {
    setWorking(true);
    const res = await refuseFeedback({ continueMatching: c });
    setWorking(false);
    if (!res.success) {
      setError(true);
      return;
    }
    setWorkflowActive(false);
  };

  const text = nextItem ? nextMatchText : nextText;

  if (showContinueMatching) {
    return (
      <>
        <Text>{continueSearchingText}</Text>
        {error && <Error>{errorText}</Error>}
        <p>
          <Button
            full
            onClick={() => continueSearching(true)}
            disabled={working}
          >
            {continueSearchText}
          </Button>
        </p>
        <p>
          <Button
            full
            onClick={() => continueSearching(false)}
            disabled={working}
          >
            {stopSearchText}
          </Button>
        </p>
      </>
    );
  }

  return (
    <Wrapper>
      <Text>{whyDeclineText}</Text>
      <TextInput
        value={message}
        onChange={({ target }) => setMessage(target.value)}
      />
      {error && <Error>{errorText}</Error>}
      <Button full onClick={next} disabled={working}>
        {text}
      </Button>
    </Wrapper>
  );
};

export default RefuseWorkflow;
