import { useState, useContext } from "react";
import styled from "styled-components";
import PageWrapper from "./shared/PageWrapper";
import SignInFlow from "../components/SignIn/Flow";
import ItemList from "../components/ItemList";
import RealtimeWebsocket from "../components/Websocket/RealtimeWebsocket";
import { UserContext } from "../providers/UserProvider";
import { AppStateContext } from "../providers/AppStateProvider";

const Panel = styled.div`
  background: #fff;
  flex: 1;
`;

const PanelWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex: 1 0 auto;
    margin: 0 auto;
    width: calc(60%);
    min-width: 800px;
    max-width: 900px;
    position: absolute;
    top: 116px;
    right: 0;
    left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-radius: 16px;
  }
`;

const SignInWrapper = styled.div`
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    align-items: center;
  }
`;

export default function LandingView() {
  const [currentStep, setCurrentStep] = useState("sign-in");
  const { loading } = useContext(AppStateContext);
  const { signedIn } = useContext(UserContext);

  // TODO: loading state
  if (loading) {
    return null;
  }

  return (
    <PageWrapper authenticated>
      <PanelWrapper>
        <Panel>
          {!signedIn ? (
            <SignInWrapper>
              <SignInFlow page={currentStep} onChange={setCurrentStep} />
            </SignInWrapper>
          ) : (
            <>
              <ItemList />
              <RealtimeWebsocket />
            </>
          )}
        </Panel>
      </PanelWrapper>
    </PageWrapper>
  );
}
