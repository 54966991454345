import { useState, createContext, useCallback } from "react";
import { CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { logoutUser, getCurrentUser } from "../../httpapi";
import { getKMSClient } from "../../idp";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";

const refreshTokens = ({ email, refreshToken }) => {
  const userData = {
    Username: email,
    Pool: userPool,
    Storage: MemoryStorage,
  };
  const cognitoUser = new CognitoUser(userData);
  const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
  return new Promise((resolve, reject) =>
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session);
    }),
  );
};

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ locale: "en-US" });
  const [signedIn, setSignedIn] = useState(null);
  const [verified, setVerified] = useState(null);
  const [client, setClient] = useState(null);
  const navigate = useNavigate();

  const signOut = async () => {
    const { success } = await logoutUser();
    if (success) {
      setSignedIn(false);
      setUser({});
      navigate("/");
    }
  };

  const signInUser = useCallback(async u => {
    if (!u) {
      return;
    }
    const c = await getKMSClient(u.idToken);
    // don't save tokens in app
    u.idToken = undefined;
    u.refreshToken = undefined;
    setUser(u);
    setClient(c);
    setSignedIn(true);
  }, []);

  const fetchCurrentUser = useCallback(async () => {
    const u = await getCurrentUser();
    if (!u) {
      return;
    }
    const tokens = await refreshTokens(u);
    u.idToken = tokens?.idToken?.jwtToken;
    signInUser(u);
  }, [signInUser]);

  return (
    <UserContext.Provider
      value={{
        fetchCurrentUser,
        signInUser,
        signOut,
        user,
        client,
        verified,
        setVerified,
        signedIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
