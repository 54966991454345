import styled from "styled-components";
import PageWrapper from "../../shared/PageWrapper";
import Header from "./Header";
import HeaderSmall from "./HeaderSmall";
import ContentFooter from "./Footer";

const Content = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  background: white;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    flex: 1 0 auto;
    margin: 0 auto;
    width: calc(60%);
    min-width: 800px;
    max-width: 900px;
    position: absolute;
    top: 116px;
    right: 0;
    left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-radius: 16px;
    height: 80vh;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

const Body = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  padding-top: 0;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 100%;
    margin: 0 auto;
    padding-top: ${({ gap }) => (gap ? "16px" : "0")};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 24px;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    padding: ${({ center }) => (center ? "32px 0" : "0 24px")};
    width: ${({ center }) => (center ? "600px" : "100%")};
  }
`;

const SurveyTemplate = ({
  children,
  title,
  onSubmit,
  submitText,
  submitDisabled,
  headerVariant = "big",
}) => {
  return (
    <PageWrapper>
      <Content>
        <Inner>
          {headerVariant === "small" ? (
            <HeaderSmall title={title} />
          ) : (
            <Header title={title} />
          )}
          <Body gap={headerVariant === "big"}>
            <Wrapper center={headerVariant === "big"}>{children}</Wrapper>
          </Body>
          {onSubmit && (
            <ContentFooter
              onNext={onSubmit}
              nextText={submitText}
              disabled={submitDisabled}
            />
          )}
        </Inner>
      </Content>
    </PageWrapper>
  );
};

export default SurveyTemplate;
