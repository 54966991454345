import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { Loading } from "@sussex/react-kit/assets";
import { Button } from "@sussex/react-kit/elements";
import { Statuses } from "@sussex/match-utils";
import useCopy from "../../hooks/useCopy";
import { confirmMatch, refuseMatch, sendFeedback } from "../../httpapi";
import { ItemContext } from "../../providers/ItemProvider";
import ChatLog from "../Chat/ChatLog";
import DetailWrapper from "./DetailWrapper";
import RequestDetail from "./RequestDetail";
import RefuseWorkflow from "./RefuseWorkflow";
import StatusMessage from "./StatusMessage";

const NoneHeaderWrapper = styled.div`
  ${({ theme }) => theme.breakpoints[">mobile"]} {
    display: none;
  }
`;

const Section = styled.div`
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  margin: 15px;
`;

const Footer = styled.div`
  border-bottom: none;
  padding: 10px 0;
`;

const NoneWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ItemDetail = ({ item }) => {
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [working, setWorking] = useState(false);
  const [workflowActive, setWorkflowActive] = useState(false);
  const itemId = useRef(null);
  const { updateStatus, loadMessages, items } = useContext(ItemContext);

  const [acceptText, declineText, proposeChangeText] = useCopy([
    "client.matchSuccess.confirm",
    "client.matchSuccess.decline",
    "client.matchSuccess.proposeChange",
  ]);

  const showChatLog =
    item.status === Statuses.confirmed || item.status === Statuses.scheduled;

  const schedule = async () => {
    setWorking(true);
    const res = await confirmMatch({
      requestId: item.requestId,
      id: item.id,
      acceptDatetime: true,
    });
    if (res.success) {
      updateStatus(item, Statuses.scheduled);
    }
    setWorking(false);
  };

  const confirm = async () => {
    setWorking(true);
    const res = await confirmMatch({
      requestId: item.requestId,
      id: item.id,
      acceptDatetime: false,
    });
    if (res.success) {
      updateStatus(item, Statuses.confirmed);
    }
    setWorking(false);
  };

  const refuse = async () => {
    setWorking(true);
    const res = await refuseMatch({
      requestId: item.requestId,
      id: item.id,
    });
    if (res.success) {
      updateStatus(item, Statuses.refused);
    }
    setWorking(false);
    setWorkflowActive(true);
  };

  const refuseFeedback = ({ message, continueMatching }) =>
    sendFeedback({
      requestId: item.requestId,
      matchId: item.id,
      type: "match_refused",
      text: message,
      continueMatching,
    });

  useEffect(() => {
    if (!item || item?.id === itemId.current) {
      return;
    }
    setLoading(true);
    itemId.current = item.id;
    const load = async () => {
      await loadMessages(item);
      setLoading(false);
    };
    load();
  }, [item, loadMessages]);

  if (item.type === "request") {
    return (
      <DetailWrapper
        setShowCancelConfirm={setShowCancelConfirm}
        showCancelConfirm={showCancelConfirm}
        item={item}
      >
        <RequestDetail
          item={item}
          setShowCancelConfirm={setShowCancelConfirm}
        />
      </DetailWrapper>
    );
  }

  if (!item) {
    return (
      <DetailWrapper>
        <NoneHeaderWrapper></NoneHeaderWrapper>
        <NoneWrapper>None Selected</NoneWrapper>
      </DetailWrapper>
    );
  }

  if (loading) {
    return (
      <DetailWrapper
        setShowCancelConfirm={setShowCancelConfirm}
        showCancelConfirm={showCancelConfirm}
        item={item}
      >
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </DetailWrapper>
    );
  }

  if (showChatLog) {
    return (
      <DetailWrapper
        item={item}
        setShowCancelConfirm={setShowCancelConfirm}
        showCancelConfirm={showCancelConfirm}
      >
        <ChatLog item={item}>
          {!item.hasMoreMessages ? <StatusMessage item={item} /> : null}
        </ChatLog>
      </DetailWrapper>
    );
  }

  if (workflowActive) {
    const nextItem = items?.find(
      i =>
        i.requestId === item.requestId &&
        i.id !== item.id &&
        i.status === Statuses.accepted,
    );

    return (
      <DetailWrapper
        item={item}
        setShowCancelConfirm={setShowCancelConfirm}
        showCancelConfirm={showCancelConfirm}
      >
        <Section>
          <RefuseWorkflow
            nextItem={nextItem}
            item={item}
            refuseFeedback={refuseFeedback}
            setWorkflowActive={setWorkflowActive}
          />
        </Section>
      </DetailWrapper>
    );
  }

  return (
    <DetailWrapper
      item={item}
      setShowCancelConfirm={setShowCancelConfirm}
      showCancelConfirm={showCancelConfirm}
    >
      <StatusMessage item={item}>
        {item.status === Statuses.accepted && (
          <Footer>
            <p>
              <Button full onClick={schedule} disabled={working}>
                {acceptText}
              </Button>
            </p>
            <p>
              <Button full onClick={confirm} disabled={working}>
                {proposeChangeText}
              </Button>
            </p>
            <p>
              <Button full onClick={refuse} disabled={working}>
                {declineText}
              </Button>
            </p>
          </Footer>
        )}
      </StatusMessage>
    </DetailWrapper>
  );
};

export default ItemDetail;
