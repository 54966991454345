import styled from "styled-components";
import { Statuses } from "@sussex/match-utils";

import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  background-color: #e0e7ff;
  color: #477be4;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 5px;
  text-align: center;
`;

const MatchStatus = ({ status }) => {
  const [
    statusPending,
    statusAccepted,
    statusDeclined,
    statusCanceled,
    statusCompleted,
    statusExpired,
    statusClosed,
  ] = useCopy([
    "matchStatus.pending",
    "matchStatus.accepted",
    "matchStatus.declined",
    "matchStatus.canceled",
    "matchStatus.completed",
    "matchStatus.expired",
    "matchStatus.closed",
  ]);

  const statusValues = {
    open: statusPending,
    closed: statusClosed,
    [Statuses.pending]: statusPending,
    [Statuses.pendingAccepted]: statusPending,
    [Statuses.accepted]: statusAccepted,
    [Statuses.confirmed]: statusCompleted,
    [Statuses.scheduled]: statusCompleted,
    [Statuses.refused]: statusDeclined,
    [Statuses.declined]: statusDeclined,
    [Statuses.expired]: statusExpired,
    [Statuses.canceled]: statusCanceled,
  };
  const text = statusValues[status];
  if (!text) {
    return null;
  }
  return <Wrapper>{text}</Wrapper>;
};

export default MatchStatus;
