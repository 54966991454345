import { useContext } from "react";
import { ChatLog as Log } from "@sussex/react-kit/message-center";
import { ItemContext } from "../../providers/ItemProvider";
import { UserContext } from "../../providers/UserProvider";
import useCopy from "../../hooks/useCopy";

const ChatLog = ({ item, children }) => {
  const { loadPrevMessages } = useContext(ItemContext);
  const { user } = useContext(UserContext);
  const [errorText, readText] = useCopy([
    "messageCenter.itemChat.sendMessageError",
    "messageCenter.matchChat.readReceipt",
  ]);

  const messages = (item.messages || []).map(m => ({
    createdAt: m.createdAt * 1000,
    id: m.id,
    self: m.senderId === user.uuid,
    message: m.decrypted,
    error: m.error,
  }));

  const getNextPage = async () => {
    await loadPrevMessages(item);
  };

  const readMessages =
    item.lastTherapistReadAt > 0
      ? messages
          .filter(e => e.createdAt <= item.lastTherapistReadAt * 1000 && e.self)
          .sort((a, b) => b.createdAt - a.createdAt)
      : [];

  const lastReadMessageID = readMessages?.[0]?.id || null;

  const lastReadDate = item.lastTherapistReadAt
    ? new Date(item.lastTherapistReadAt * 1000)
    : null;

  return (
    <Log
      messages={messages}
      hasMore={item.hasMoreMessages}
      locale={navigator.language}
      getNextPage={getNextPage}
      errorText={errorText}
      readText={readText}
      lastReadDate={lastReadDate}
      lastReadMessageID={lastReadMessageID}
    >
      {children}
    </Log>
  );
};

export default ChatLog;
