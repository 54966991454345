import { useState } from "react";
import styled from "styled-components";
import { Pill, InfoBox, ErrorBox } from "@sussex/react-kit/elements";
import SurveyTemplate from "./shared/SurveyTemplate";
import useCopy from "../../hooks/useCopy";
import LimitedTextBox from "../../components/LimitedTextBox";
import ContinuePanel from "./shared/ContinuePanel";
import { tokenSendFeedback } from "../../httpapi";

const Text = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
`;

const Pills = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  padding-bottom: 16px;
`;

const MessagePanel = styled.div`
  margin-top: 32px;
`;

const Introduction = () => {
  const [continueMatching, setContinueMatching] = useState(true);
  const [
    title,
    submitText,
    declineText,
    counterText,
    unavailableText,
    changedMindText,
    paymentText,
    otherText,
    successText,
    errorText,
  ] = useCopy([
    "client.feedback.introductionCall.title",
    "client.feedback.submitFeedback",
    "client.feedback.declineReason",
    "client.feedback.charactersCounter",
    "client.feedback.declineReason.unavailable",
    "client.feedback.declineReason.changedMind",
    "client.feedback.declineReason.payment",
    "client.feedback.declineReason.other",
    "client.feedback.thankYou",
    "client.confirmLanding.genericError",
  ]);
  const [currentOption, setCurrentOption] = useState("");
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [error, setError] = useState(false);

  const options = {
    intro_therapist_unavailable: {
      text: unavailableText,
      showMessage: false,
      showContinue: true,
    },
    intro_client_changed_mind: {
      text: changedMindText,
      showMessage: false,
      showContinue: false,
    },
    intro_payment_insurance_issues: {
      text: paymentText,
      showMessage: false,
      showContinue: true,
    },
    intro_other: {
      text: otherText,
      showMessage: true,
      showContinue: true,
    },
  };

  const sendFeedback = () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!token) {
      setError(true);
      setFeedbackSent(true);
      return;
    }

    setProcessing(true);

    const send = async () => {
      const res = await tokenSendFeedback({
        token,
        type: currentOption,
        text: message,
        continueMatching,
      });
      if (!res.success) {
        setError(true);
      }
      setProcessing(false);
      setFeedbackSent(true);
    };

    send();
  };

  const formDisabled = () => {
    if (options[currentOption].showMessage) {
      return message.length > 500 || message.length === 0;
    }
    return false;
  };

  return feedbackSent ? (
    <SurveyTemplate title={title} headerVariant="small">
      {error ? (
        <ErrorBox>{errorText}</ErrorBox>
      ) : (
        <InfoBox>{successText}</InfoBox>
      )}
    </SurveyTemplate>
  ) : (
    <SurveyTemplate
      title={title}
      onSubmit={sendFeedback}
      submitText={submitText}
      submitDisabled={currentOption === "" || formDisabled() || processing}
      headerVariant="small"
    >
      <Pills>
        {Object.keys(options).map(opt => (
          <div key={opt}>
            <Pill
              selected={opt === currentOption}
              onClick={() => {
                setContinueMatching(options[opt].showContinue);
                setMessage("");
                setCurrentOption(opt);
              }}
            >
              {options[opt].text}
            </Pill>
            {options[opt].showMessage && opt === currentOption && (
              <MessagePanel>
                <Text>{declineText}</Text>
                <LimitedTextBox
                  message={message}
                  onChange={setMessage}
                  maxLength={500}
                  counterText={counterText}
                  rows={4}
                />
              </MessagePanel>
            )}
            {options[opt].showContinue && opt === currentOption && (
              <ContinuePanel onChange={setContinueMatching} />
            )}
          </div>
        ))}
      </Pills>
    </SurveyTemplate>
  );
};

export default Introduction;
