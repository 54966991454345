import styled from "styled-components";

const Wrapper = styled.div`
  margin-right: 12px;
  overflow: hidden;

  > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`;

const Photo = ({ item }) => {
  return (
    <Wrapper>
      <img src={item.photo} alt={item.title} />
    </Wrapper>
  );
};

export default Photo;
