import { swapCopyVariables } from "@sussex/react-kit/utils";
import AppointmentCard from "./AppointmentCard";
import AppointmentDetails from "./AppointmentDetails";
import useCopy from "../../hooks/useCopy";
import calendarLinks from "./calendarlinks";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 15px;
`;

const getPhotoUrl = id => {
  return `${process.env.REACT_APP_BASE_DIRECTORY_API_URL}/profile-photo/${id}`;
};

const MatchAppointmentCard = ({ match, therapist }) => {
  const [calendarSummary, calendarDetails] = useCopy([
    "client.confirmLanding.appointmentDetails.summary",
    "client.confirmLanding.appointmentDetails.details",
  ]);

  return (
    <Wrapper>
      <AppointmentCard
        photoItem={{
          photo: getPhotoUrl(therapist.id),
        }}
        name={therapist.fullName}
        title={therapist.title}
        appointmentDetails={
          <AppointmentDetails match={match} therapist={therapist} />
        }
        calendarLinks={calendarLinks({
          startTime: match.proposedDatetime,
          endTime: match.proposedEndDatetime,
          method: match.appointmentMethod,
          locationUrl:
            match.appointmentMethod === "sessions" ? match.sessionsUrl : "",
          summary: encodeURIComponent(calendarSummary),
          details: encodeURIComponent(
            swapCopyVariables(calendarDetails, {
              NAME: therapist.fullName,
            }).join(""),
          ),
        })}
      />
    </Wrapper>
  );
};

export default MatchAppointmentCard;
