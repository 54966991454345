import {
  KMSClient,
  GenerateDataKeyCommand,
  DecryptCommand,
} from "@aws-sdk/client-kms";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import themis from "wasm-themis";

const appRegion = process.env.REACT_APP_AWS_REGION;
const cognitoRegion = process.env.REACT_APP_REGION;
const idp = process.env.REACT_APP_IDP;
const cognitoIDP = process.env.REACT_APP_COGNITO_IDENTITY;
const generatorKeyId = process.env.REACT_APP_KEY_ID;

const decryptCache = {};

const generateDataKey = async client => {
  const command = new GenerateDataKeyCommand({
    KeyId: generatorKeyId,
    KeySpec: "AES_256",
  });
  const response = await client.send(command);
  return response;
};

const decryptDataKey = async (client, dataKey) => {
  const command = new DecryptCommand({
    CiphertextBlob: dataKey,
    KeyId: generatorKeyId,
  });
  const response = await client.send(command);
  return response;
};

const fromBase64 = d => Uint8Array.from(atob(d), c => c.charCodeAt(0));
const toBase64 = uInt8Array => btoa(String.fromCharCode(...uInt8Array));

const encrypt = async (key, bytes) => {
  const scellMK = themis.SecureCellSeal.withKey(key);
  const encrypted_message = scellMK.encrypt(bytes);
  return encrypted_message;
};

const decrypt = async (key, message) => {
  const scellMK = themis.SecureCellSeal.withKey(key);
  const decrypted_message = scellMK.decrypt(fromBase64(message));
  return decrypted_message;
};

export const decryptText = async (client, hashedEncrypted) => {
  if (!hashedEncrypted) {
    return "";
  }
  if (decryptCache[hashedEncrypted]) {
    return decryptCache[hashedEncrypted];
  }
  try {
    const encryptedBlob = atob(hashedEncrypted);
    const encrypted = JSON.parse(encryptedBlob);
    const decryptedKey = await decryptDataKey(
      client,
      fromBase64(encrypted.key),
    );
    const decrypted = await decrypt(decryptedKey.Plaintext, encrypted.data);
    const dec = new TextDecoder();
    const decoded = dec.decode(decrypted);
    decryptCache[hashedEncrypted] = decoded;
    return decoded;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const encryptText = async (client, message) => {
  try {
    const generatedKey = await generateDataKey(client);
    const enc = new TextEncoder();
    const bytes = enc.encode(message);
    const encryptedData = await encrypt(generatedKey.Plaintext, bytes);
    const payload = {
      key: toBase64(generatedKey.CiphertextBlob),
      data: toBase64(encryptedData),
    };
    const stringified = JSON.stringify(payload);
    const hashedEncrypted = btoa(stringified);
    return hashedEncrypted;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const getKMSClient = async idToken => {
  if (!idToken) {
    return null;
  }

  const client = new KMSClient({
    region: appRegion,
    credentials: fromCognitoIdentityPool({
      identityPoolId: idp,
      logins: {
        [cognitoIDP]: idToken,
      },
      clientConfig: { region: cognitoRegion },
    }),
  });

  return client;
};
